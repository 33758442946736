/* 見出し画像調整--------------------------*/
h3.h_img, h4.h_img{
  background: none;
  padding: 0;
  margin: 20px 0 10px;
  border-radius: 0;
  border: none;
}

h4.h_img::after {
  content: inherit;
}




/* ブランドメッセージ--------------------------*/

.message_bg{
  background-image: url(../images/messasge/message_bg.jpg);
  background-size: cover;
  margin-top: 100px;
  padding-bottom: 2em;
}

.message_txt_area{
  padding-left: 70px;

  > p{
    margin-bottom: 1em;
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
    color: #06a4e6;

    &.mt2em{
      margin-top: 2em;
    }
    &.message_logo{
      font-size: 24px;
      > img{
        margin-top: 0.5em;
      }
    }
  }

}

.message_catch{
  margin-bottom: 60px;
}

.message_txt_box{
  margin-top: 200px;
  position: relative;
  border: 2px solid #06a4e6;
  border-radius: 6px;
  background-image: url(../images/messasge/message_pattern.png);

  .txt_inner{
    padding: 25px 1.5em 1.5em;
    > p{
      font-weight: bold;
      padding: 0;
      font-size: 14px;
      &.txt_blue{
        font-size: 24px;
        font-weight: bold;
        color: #06a4e6;
      }
    }
  }

}

h3.message_tit{
  position: relative;
  left: -1px;
  top: 10px;
  padding: 0;
  background: none;
}



/* SP固定メニューPC非表示--------------------------*/
.sp_nav{
  display: none;
}

.fixed_bottom_sp{
  display: none;
}



@media only screen and (max-width: 767px) {

/* レイアウト調整--------------------------*/
  h2{
    height: 110px;
  }


  .mm_area_01, .mm_area_02 {
      padding: 0;
      background-size: contain;
      background-position: 0 0;
      padding-top: 30%;
  }



  .btn_t_list ul{
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    li{
      width: 30%;
      padding: 0;
      margin-top: 1em;
      margin-bottom: 1em;
      a img{
        width: 100%;
      }
    }
    &::after{
      content:"";
      display: block;
      width: 30%;
    }
  }


  .btn_u_list{
    > a{
        margin-bottom: 1em;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
    }

  }

  .btn_p a{
    box-sizing: border-box;
    width: 100%;
  }


  .sp_p3{
    padding: 0 3%;
  }

  .sp_t_left{
    text-align: left;
  }

  /* ブランドメッセージ--------------------------*/

  .message_bg{
    background-image: none;
    margin-top: 3em;
    padding-bottom: 2em;
  }

  .message_txt_area{
    padding-left: 1em;
    height: 675px;
    background-image: url(../images/messasge/message_bg.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    > p{
      margin-bottom: 1em;
      font-size: 12px;
      line-height: 2;

      &.mt2em{
        margin-top: 2em;
      }
      &.message_logo{
        font-size: 16px;
        > img{
          width: 150px;
          margin-top: 0.5em;
        }
      }
    }

  }

  .message_catch{
    margin-bottom: 3em;
    > img{
      width: 100px;
    }
  }

  .message_txt_box{
    margin: 3em 3% 0;

    .txt_inner{
      padding: 25px 1.5em 1.5em;
      > p{
        font-size: 14px;
        &.txt_blue{
          font-size: 16px;
        }
      }
    }

  }

  h3.message_tit{
    position: relative;
    left: -1px;
    top: 10px;
    padding: 0;
    background: none;
    > img{
      width: 150px;
    }
  }




  /* SP固定メニュー--------------------------*/
  /* SPナビ--------------------------*/
  .sp_nav{
    display: block;
  }
  //headerのレイアウト
  .header {
    width: 100%;
    z-index: 3;

    .menu_btn_wrap{
      //position: absolute;
      position: fixed;
      width: 50px;
      height: 50px;
      top: 10px;
      right: 10px;
      z-index: 3;
      background-color: #3399FF;
    }
  }

  //アコーディオンメニューの中身
  .nav_content{
    //position: absolute;
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 100vh;//fixed
    padding-top: 50px;//fixed
    //top: -735px;
    top: -150vh;
    z-index: 2;
    ul li{
      background-color: #fff;
      a{
        text-align: center;
        display: block;
        padding: 20px 0px;
        color: #333;
        text-decoration: none;
        border-bottom: 1px solid #ccc;
      }
    }
    transition: 0.5s top;
  }

  //アコーディオンメニューの背景
  #js_cover{
    background:#000;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    opacity: 0;
    z-index: 1;
  }

  //アコーディオンメニューの動き
  .is_open{
    .nav_content{
      //top: 65px;
      top: 0px;
    }
  }

  .menu_btn{

    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    right: 10px;
    top: 9px;

    span {
      position: relative;
      background: none;
      transition: all 0.3s;

      width: 30px;
      height: 2px;
      display: block;
      background: #FFF;
      border-radius: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -15px;


      &:before, &:after {
        transition: all 0.3s;
        content: "";
        width: 15px;
        height: 2px;
        display: block;
        background: #FFF;
        position: absolute;
        border-radius: 1px;
      }
      &:before {
        transform: translateX(0px);
        opacity: 1;
      }
      &:after {
        transform: translateX(15px);
        opacity: 1;
      }
    }
    .top {
      transform: translateY(-10px);
    }
    .middle {
      transform: translateY(0px);
    }
    .bottom {
      transform: translateY(10px);
    }
  }


  .is_open{
    .menu_btn{
      span{
        background: none;
      }
      .middle {
        &:before {
          transform: translateX(-100px);
          opacity: 0;
        }
        &:after {
          transform: translateX(100px);
          opacity: 0;
        }
      }
      .top {
        transform: translateY(-5px);
        &:before {
          transform: translateX(3px) rotate(45deg);
        }
        &:after {
          transform: translateX(13px) rotate(-45deg);
        }
      }
      .bottom {
        transform: translateY(5px);
        &:before {
          transform: translateX(3px) rotate(-45deg);
        }
        &:after {
          transform: translateX(13px) rotate(45deg);
        }
      }
    }
  }


  /* SP下部固定CVエリア--------------------------*/
  .fixed_bottom_sp{
    display: block;
    position: fixed;
    width: 100%;
    min-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    margin-left: auto;
    margin-right: auto;
    //border-top: 1px solid rgba(204, 204, 204, 0.2);
    box-shadow:0px -1px 3px 0px #e8e8e8;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;

  }

  .fixed_bottom_sp > ul{
    display: flex;
    justify-content: space-around;
  }

  .fixed_reserve_btn{
    width: 60%;
  }

  .fixed_tel_btn{
    width: 30%;
  }

  .fixed_reserve_btn > a {
    display: block;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 110%;
  }
  .fixed_tel_btn > a {
    box-sizing:border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    //padding-top: 1.2em;
    //text-align: center;
    background-color: #3399FF;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 110%;
  }



}